import { Component, Prop, Vue } from 'vue-property-decorator'
import Header from '@/components/header/Header.vue'
import Sidebar from '@/components/sidebar/Sidebar.vue'
import BasicInput from '@/components/inputs/input/BasicInput.vue'
import SelectInput from '@/components/inputs/select/SelectInput.vue'
import TextareaInput from '@/components/inputs/textarea/TextareaInput.vue'
import VueMarkdown from 'vue-markdown'
import Button from '@/components/button/Button.vue'
import Switcher from '@/components/switcher/Switcher.vue'
import Route from 'vue-router'
import { Template } from '@/domain/entities/Template.ts'
import {
  CreateTemplateFormFieldTypes,
  CreateTemplateFormType,
  MessageTypeList
} from '@/views/template/create/CreateTemplate'
import { MessageTypeIdentificatorType, productMessageTypeList } from '@/domain/value-objects/MessageTypes'
import { SwitcherOptions } from '@/components/switcher/Switcher.ts'
import BasicModal from '@/components/modals/basic-modal/BasicModal.vue'
import { create } from 'vue-modal-dialogs'
import Confirmation from '@/components/modals/confirmation/Confirmation.vue'

const ShowBasicModal = create(BasicModal, 'title', 'content')
const ShowConfirmationModal = create(Confirmation, 'title', 'content')

@Component({
  components: {
    Sidebar,
    Header,
    BasicInput,
    SelectInput,
    TextareaInput,
    Switcher,
    Button,
    VueMarkdown,
    Confirmation,
    BasicModal
  }
})
export default class CloneTemplate extends Vue {
  private initialFormState: CreateTemplateFormType
  private form: CreateTemplateFormType = {
    templateName: '',
    messageType: Object.values(productMessageTypeList[this.$store.state.product])[0].value,
    subject: '',
    text: '',
    html: ''
  }

  private template: Template | null = null
  private discardChanges = false
  private templateSaved = false
  private switchStatus = true
  private switcherOptions: SwitcherOptions = {
    a: {
      title: 'Email html version',
      text: 'html/code'
    },
    b: {
      title: 'Email plain text version',
      text: 'plain text'
    }
  }

  @Prop({ required: true, type: String })
  readonly templateName: string

  get availablePlaceholders (): Array<string> {
    return Object.values(productMessageTypeList[this.$store.state.product])
      .find(messageType => messageType.value === this.form.messageType)!
      .placeholders
  }

  get messageTypes (): MessageTypeList {
    const productMessageTypes = productMessageTypeList[this.$store.state.product]
    return Object.keys(productMessageTypes).reduce(
      (acc: MessageTypeList, key: string) => {
        acc[key] = productMessageTypes[key].value
        return acc
      },
      {}
    )
  }

  get templateHtml (): string {
    return this.form.html.replace(/(?:\r\n|\r|\n)/g, '')
  }

  public created () {
    if (this.$store.state.template.templates.length === 0) {
      this.$store.dispatch('getTemplates').then(this.loadTemplate)
      return
    }

    this.loadTemplate()
  }

  public beforeRouteLeave (to: Route, from: Route, next: Function) {
    if (
      this.discardChanges ||
      this.templateSaved ||
      JSON.stringify(this.initialFormState) === JSON.stringify(this.form)
    ) {
      next()
      return
    }

    this.showNoSaveModal()
    this.discardChanges = true
  }

  private async showNoSaveModal () {
    this.discardChanges = await ShowConfirmationModal(
      'Warning',
      'You have unsaved changes, please save before quitting'
    )
  }

  private setFormField (formField: CreateTemplateFormFieldTypes, formValue: string) {
    if (formField === 'messageType') {
      this.form[formField] = formValue as MessageTypeIdentificatorType
      return
    }

    this.form[formField] = formValue
  }

  private switching (switcher: boolean) {
    this.switchStatus = switcher
  }

  private submit () {
    const messageType = Object.values(productMessageTypeList[this.$store.state.product])
      .find((element) => element.value === this.form.messageType)

    let template = null
    try {
      template = new Template(
        this.form.templateName,
        this.form.messageType,
        messageType!.hiddenFields.trustabilityLevel,
        messageType!.hiddenFields.useInterval,
        messageType!.hiddenFields.sender,
        'in_house',
        this.form.subject,
        this.form.text,
        this.form.html
      )
    } catch (e) {
      ShowBasicModal('Warning', e.message)
      return
    }

    this.templateSaved = true

    this.$store.dispatch('createTemplate', template.toMailerRequest())
      .then(() => {
        this.$router.push({ path: '/' })
      })
      .catch(() => {
        ShowBasicModal('Warning', 'Template name should be unique, already exist a template with this name')
      })
  }

  private loadTemplate () {
    const template: Template = this.$store.state.template.templates.find(
      (template: Template) => template.name === this.templateName
    )

    this.form = {
      templateName: '',
      messageType: template.messageType || Object.values(productMessageTypeList[this.$store.state.product])[0].value,
      subject: template.subject,
      text: template.text,
      html: template.html
    }
    this.initialFormState = { ...this.form }

    this.template = template
  }
}
